<template>
	<v-card flat class="rounded-xl">
		<v-card-subtitle>{{ $t("title") }}</v-card-subtitle>

		<v-card-text>
			<v-form ref="form" onSubmit="return false;" v-model="isFormValid">
				<v-row
					:key="index"
					class="my-0 py-0"
					v-for="(item, index) in localSelectedCompanies"
				>
					<v-col cols="10" class="my-0 py-0">
						<v-text-field
							:label="$t('administrationNr')"
							:rules="[rules.required]"
							outlined
							v-model="localSelectedCompanies[index]"
						></v-text-field>
					</v-col>
					<v-col cols="2" class="my-0 py-0">
						<v-btn @click="removeItem(index)" class="mt-2" icon>
							<v-icon color="error">close</v-icon>
						</v-btn>
					</v-col>
				</v-row>

				<v-btn @click="addItem()" :class="['primary--text']" rounded text
					>+ {{ $t("label.add") }}</v-btn
				>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn
				@click="back()"
				:class="['black--text', 'mr-2']"
				rounded
				small
				text
			>
				{{ $t("label.back") }}
			</v-btn>
			<v-btn
				@click="next()"
				:color="'success'"
				:disabled="!isFormValid || localSelectedCompanies.length == 0"
				rounded
				small
			>
				{{ $t("label.next") }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import rules from "@/utils/rules.js";

export default {
	name: "exact-online-project-install",
	props: {
		getAuthCode: {
			type: Boolean,
			default: false,
		},
		selectedCompanies: {
			type: Array,
			default: [],
		},
		step: {
			type: Number,
			default: 1,
		},
		token: {
			type: Object,
			default: {},
		},
	},
	components: {},

	apollo: {},
	data: function () {
		return {
			isFormValid: false,
			localSelectedCompanies: this.selectedCompanies,
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {},
	created() {
		if (this.localSelectedCompanies.length == 0) {
			this.addItem();
		}

		this.rules = rules;
	},
	mounted() {},
	methods: {
		addItem() {
			this.localSelectedCompanies.push("");
		},
		removeItem(index) {
			this.localSelectedCompanies.splice(index, 1);
		},
		back() {
			this.$emit("update:step", this.step - 1);
		},

		next() {
			this.$emit("update:step", this.step + 1);
		},
	},
};
</script>

<i18n>
{
  "en": {
	"title": "Enter the division numbers here for the divisions you want to connect.",
	"administrationNr": "Division Number"
  },
  "nl": {
	"title": "Vul hier de divisie nummers in van de divisies die je wilt koppelen.",
	"administrationNr": "Divisie Nummer"
  },
  "de": {
	"title": "Geben Sie hier die Verwaltungsnummern ein, für die Sie die Daten abrufen möchten.",
	"administrationNr": "Verwaltungsnummer"
  }
}
</i18n>
